import emailjs from "@emailjs/browser";

const ContattiService = (e: any) => {
  if (e !== null && e.email !== null) {
    const templateParams = {
      name: e.user.name,
      email: e.user.email,
      company: e.user.company,
      website: e.user.website,
      message: e.user.message,
    };
    emailjs
      .send(
        "service_20y52sd",
        "template_gt2wnoi",
        templateParams,
        "hrZxIGvN7Vx0t7PqS"
      )
      .then(
        (response) => {
          const event = new window.CustomEvent("EVENTMANAGER", {
            detail: "SUCCESS",
          });
          window.dispatchEvent(event);
        },
        (err) => {
          const event = new window.CustomEvent("EVENTMANAGER", {
            detail: "ERROR",
          });
          window.dispatchEvent(event);
        }
      );
  }
};
export default ContattiService;
