import Carousel from "../components/carousel/Carousel";

const Home = () => {

  return (
    <>
      <div className="cont text-black dark:text-white ">
      <br></br>
        <h1 >Edoardo Laurentino Portfolio</h1>
        <br></br>
        <Carousel />
       
      </div>

    </>
  );
};
export default Home;
