import template1 from "../../../../img/template1.png";
import template2 from "../../../../img/template2.png";
import template3 from "../../../../img/template3.png";
const CarouselImg = () => {
  const imgArray = [
    { url: "https://www.youtube.com/watch?v=ysz5S6PUM-U", type: "video" },
    { url: template1, type: "img" },
    { url: template2, type: "img" },
    { url: template3, type: "img" },
  ];
  return imgArray;
};
export default CarouselImg;
