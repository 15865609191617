import { Menu } from 'antd';
import React, { useState } from "react"
import { Route, Routes, useNavigate } from 'react-router-dom';
import About from '../about/container/About';
import Contatti from '../contatti/container/Contatti';
import Home from '../home/container/Home';
const items = [
  {
    label: 'Home',
    key: '/home',
    // icon: <MailOutlined />,
  },
  {
    label: 'Contatti',
    key: '/contatti',
    // icon: <AppstoreOutlined />,
    // disabled: true,
  }, {
    label: 'About Me',
    key: '/About',
    // icon: <AppstoreOutlined />,
    // disabled: true,
  },

  // {
  //   label: 'Navigation Three - Submenu',
  //   key: 'SubMenu',
  //   // icon: <SettingOutlined />,
  //   children: [
  //     {
  //       type: 'group',
  //       label: 'Item 1',
  //       children: [
  //         {
  //           label: 'Option 1',
  //           key: 'setting:1',
  //         },
  //         {
  //           label: 'Option 2',
  //           key: 'setting:2',
  //         },
  //       ],
  //     },
  //     {
  //       type: 'group',
  //       label: 'Item 2',
  //       children: [
  //         {
  //           label: 'Option 3',
  //           key: 'setting:3',
  //         },
  //         {
  //           label: 'Option 4',
  //           key: 'setting:4',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   label: (
  //     <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
  //       Navigation Four - Link
  //     </a>
  //   ),
  //   key: 'alipay',
  // },
];



 const TopMenu =( darkModes:any ) => {
  const [current, setCurrent] = useState('/');
  const navigate = useNavigate();
  const onClick = (e: { key: React.SetStateAction<string>; }) => {
    setCurrent(e.key);
    let navigatTo = e.key
    navigate(navigatTo.toString());
  };
  return (
   <>
      <div className='menu bg-white dark:bg-black" '>
        {!darkModes["darkModes"]||darkModes["darkModes"]==="light"?<>
        <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" style={
        {width:"100%",
        display: "flex",
        justifyItems: "center",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        
    }}  items={items} 
    className={'menu bg-white  text-black '}/></>:
    <> <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" style={
      {width:"100%",
      display: "flex",
      justifyItems: "center",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
  }}theme="dark" items={items} 
  className={'menu bg-black text-white '}/></>}
     
   
      </div>
<div className='containerz'>
      <Routes>
        <Route path="/" element={
          <Home />
        } />
        <Route path="/home" element={
          <Home />
        } />

        <Route path="/contatti" element={
          <Contatti />
        } />
        <Route path="/about" element={
          <About />
        } />
      </Routes>
      </div>
      </>

  );
}
export default TopMenu