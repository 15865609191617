import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import CarouselFor from "./CarouselFor";
import CarouselImg from "./CarouselImg";

const Carousel = () => {
  const [counter, changeCounter] = useState(0);
  const [auto] = useState(false);

  const imgArry = CarouselImg();
  const lenghtImg = imgArry.length - 1;
  if (counter < 0) {
    changeCounter(lenghtImg);
  }
  if (counter > lenghtImg) {
    changeCounter(0);
  }
  const changeCounterClickPlus = () => {
    changeCounter(counter + 1);
  };
  const changeCounterClickMinus = () => {
    changeCounter(counter - 1);
  };
  useEffect(() => {
    if (auto) {
      setTimeout(() => {
        changeCounter(counter + 1);
      }, 15000);
    }
  });
  return (
    <div className="carousel">
      <button
        className="corouselBottoneSinistro"
        onClick={changeCounterClickMinus}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="48"
          viewBox="0 96 960 960"
          width="48"
          color="white">
          <path fill="currentColor" d="M123 472q-4-2-4-6.5t2-8.5q62-86 157-133t203-47q108 0 203.5 46T843 455q3 5 2.5 8t-3.5 6q-3 3-7.5 3t-8.5-5q-59-82-150.5-126T481 297q-103 0-193 44.5T138 467q-4 5-7.5 6t-7.5-1Zm477 503q-103-26-169.5-103T364 685q0-47 34.5-79t82.5-32q48 0 82.5 32t34.5 79q0 38 29.5 64t68.5 26q38 0 66.5-26t28.5-64q0-123-91.5-206T481 396q-127 0-218.5 83T171 685q0 24 5.5 62.5T200 835q2 5 0 7.5t-5 4.5q-4 2-8.5 1t-6.5-6q-13-38-20.5-77.5T152 685q0-129 98-220.5T481 373q136 0 233.5 90T812 685q0 46-34 78t-82 32q-49 0-84-32t-35-78q0-39-28.5-65T481 594q-39 0-68 26t-29 65q0 104 63 173.5T604 956q6 2 7.5 5t.5 7q-1 5-4 7t-8 0ZM247 255q-5 2-7.5.5T235 251q-2-2-2-6t3-6q57-31 119.5-47T481 176q65 0 127.5 16T728 237q5 2 5.5 6t-1.5 7q-2 3-5.5 5t-8.5 0q-55-27-115-42.5T481 197q-62 0-121 14.5T247 255Zm134 709q-58-60-90.5-126T258 685q0-89 65.5-150T481 474q92 0 158.5 61T706 685q0 5-2.5 7.5T696 695q-5 0-8-2.5t-3-7.5q0-81-60.5-136T481 494q-83 0-142.5 55T279 685q0 85 29.5 145T396 950q4 4 3.5 7.5T396 964q-2 2-6.5 3.5T381 964Zm306-73q-88 0-152.5-58.5T470 685q0-5 2.5-8t7.5-3q5 0 7.5 3t2.5 8q0 81 59.5 133.5T687 871q8 0 19-1t24-3q5-1 8 1.5t4 5.5q1 4-.5 7t-6.5 4q-18 5-31.5 5.5t-16.5.5Z" />
        </svg>
      </button>
      <CarouselFor counter={counter} imgArry={imgArry} />
      <button
        className="corouselBottoneDestro"
        onClick={changeCounterClickPlus}>
            <svg
          xmlns="http://www.w3.org/2000/svg"
          height="48"
          viewBox="0 96 960 960"
          width="48"
          color="white">
          <path  fill="currentColor" d="M123 472q-4-2-4-6.5t2-8.5q62-86 157-133t203-47q108 0 203.5 46T843 455q3 5 2.5 8t-3.5 6q-3 3-7.5 3t-8.5-5q-59-82-150.5-126T481 297q-103 0-193 44.5T138 467q-4 5-7.5 6t-7.5-1Zm477 503q-103-26-169.5-103T364 685q0-47 34.5-79t82.5-32q48 0 82.5 32t34.5 79q0 38 29.5 64t68.5 26q38 0 66.5-26t28.5-64q0-123-91.5-206T481 396q-127 0-218.5 83T171 685q0 24 5.5 62.5T200 835q2 5 0 7.5t-5 4.5q-4 2-8.5 1t-6.5-6q-13-38-20.5-77.5T152 685q0-129 98-220.5T481 373q136 0 233.5 90T812 685q0 46-34 78t-82 32q-49 0-84-32t-35-78q0-39-28.5-65T481 594q-39 0-68 26t-29 65q0 104 63 173.5T604 956q6 2 7.5 5t.5 7q-1 5-4 7t-8 0ZM247 255q-5 2-7.5.5T235 251q-2-2-2-6t3-6q57-31 119.5-47T481 176q65 0 127.5 16T728 237q5 2 5.5 6t-1.5 7q-2 3-5.5 5t-8.5 0q-55-27-115-42.5T481 197q-62 0-121 14.5T247 255Zm134 709q-58-60-90.5-126T258 685q0-89 65.5-150T481 474q92 0 158.5 61T706 685q0 5-2.5 7.5T696 695q-5 0-8-2.5t-3-7.5q0-81-60.5-136T481 494q-83 0-142.5 55T279 685q0 85 29.5 145T396 950q4 4 3.5 7.5T396 964q-2 2-6.5 3.5T381 964Zm306-73q-88 0-152.5-58.5T470 685q0-5 2.5-8t7.5-3q5 0 7.5 3t2.5 8q0 81 59.5 133.5T687 871q8 0 19-1t24-3q5-1 8 1.5t4 5.5q1 4-.5 7t-6.5 4q-18 5-31.5 5.5t-16.5.5Z" />
        </svg>
        
      </button>
    </div>
  );
};

export default Carousel;
// <LeftOutlined style={{ fontSize: '2rem', color: '#08c' }} />
{/* <RightOutlined style={{ fontSize: "2rem", color: "#08c" }} /> */}