import { Form, Input } from "antd";
import ContattiService from "./ContattiService";

const ContattForm =()=> {
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  /* eslint-enable no-template-curly-in-string */

  const onFinish = (values: any) => {
    if(values.email!==null){
      ContattiService(values)
    }
   
  };

  return (
    <Form onFinish={onFinish} validateMessages={validateMessages} >
      <Form.Item
        name={["user", "name"]}
        label="Name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["user", "email"]}
        label="Email"
        rules={[{ type: "email" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name={["user", "company"]} label="Company">
        <Input />
      </Form.Item>
      <Form.Item name={["user", "website"]} label="Website">
        <Input />
      </Form.Item>
      <Form.Item name={["user", "message"]} label="Introduction">
        <Input.TextArea />
      </Form.Item>
     <button className="buttonSend bg-black   text-white hover:bg-regal-blue" style={{ width: "100%" }} type="submit">
        Submit
      </button>
    </Form>
  );
};
export default ContattForm