import { Card, Progress } from "antd";
const SecondHome = () => {
  return (
    <>
      <div className="rowSecond">
        <div className="colSecond">
          <Card
            title="Projects"
            bordered={false}
            style={{
              width: 300,
            }}
          >
            <div>
              Angular <Progress percent={0} />
            </div>
            <div>
              React <Progress percent={70} />
            </div>
            <div>
              Svelte <Progress percent={0} />
            </div>
          </Card>
        </div>
        <div className="colSecond">
          <Card
            title="Study"
            bordered={false}
            style={{
              width: 300,
            }}
          >
            <div>
              Aws Certification <Progress percent={50} />
            </div>
          </Card>
        </div>
        <div className="colSecond">
          <Card
            title="About Me"
            bordered={false}
            style={{
              width: 300,
            }}
          >
            <p></p>
          </Card>
        </div>
      </div>

    </>
  );
};
export default SecondHome