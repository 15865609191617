import SecondHome from "../components/SecondHome";


const About = () => {
  
  // window.addEventListener("message",(e)=> console.log(e))
  // const event = new window.CustomEvent("message",{detail:"test"})
  // window.dispatchEvent(event)
    return (
  <>

   <div className="cont text-black dark:text-white"> 
   <br></br>
   <h1>About Me</h1>
   <br></br>
      <SecondHome />
    
      </div>
  </>
    );
  }
  export default About
  