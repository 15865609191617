import { Card } from "antd";
import ContattiForm from "../components/ContattiForm";


const Contatti =() => {
  return (
    <div className="cont ">
      
      <br></br>
      <div className="center">
  
        <h1 className=" text-black dark:text-white">Send Me an Email</h1>
        <br></br>
      </div>
      <div className="center ">
        <Card
          bordered={false}
          style={{
            height: "auto",
            width: "80%",
          }}
          className="overflow"
        >
          <div className="contattiform ">
            <ContattiForm />
          </div>
        </Card>
      </div>
      
    </div>
  );
};
export default Contatti