
import ReactPlayer from "react-player";
const CarouselFor = (counterZ:any) => {
  const contentStyle = {
    height: "60dvh",
  };
  const styleImg = {
    maxWidth: "100%",  width: "100%",
    height: "100%",
  };
  const styleVideo = {
    width: "100%",
    height: "100%",
  };
return  <>{
   (counterZ.imgArry !==null && counterZ.imgArry[counterZ.counter].type === "video")?(
    <div>
      <h3 style={contentStyle}>
        <ReactPlayer
          style={styleVideo}
          width="100%"
          height="100%"
          url={counterZ.imgArry[counterZ.counter].url}
        />
      </h3>
    </div>
    ): ( <div>
    <h3 style={contentStyle}>
      <img src={counterZ.imgArry[counterZ.counter].url} style={styleImg} alt="Logo" />
    </h3>
  </div>)
    }</>
};
export default CarouselFor;
