import { Alert, Space } from "antd";
import {  useState } from "react";

const EventManager =() => {
  const [detail, setDetatil] = useState("");
  const [see, setSee] = useState(true);

  window.addEventListener("EVENTMANAGER", (e: any) => {
    if (e.detail !== null) {
      setDetatil(e.detail);
      setSee(true);
    }
  });

  const onClickClose = (values: any) => {
    setSee(false);
  };
  
  if (detail === "SUCCESS" && see) {
    return (
      <div
        onClick={onClickClose}
        className="center"
        style={{ color: "red", zIndex: "777" }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert message="Email send success" type="success" />
        </Space>
      </div>
    );
  } else if (detail === "ERROR" && see) {
    return (
      <div
        onClick={onClickClose}
        className="center"
        style={{ color: "red", zIndex: "777" }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            message="Error to send email please retry later"
            type="error"
          />
        </Space>
      </div>
    );
  }
  return <></>;
};
export default EventManager