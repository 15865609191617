import "./App.css";

import TopMenu from "./components/top-menu/TopMenu";
import CockieManagerScript from "./../src/generico/CockieManagerScript";

import EventManager from "./generico/EventManager";
import { useState } from "react";

function App() {
  // toDo List:
  // aggiungere captcha prima di invio Email
  // completare pagina about me
  // refactorin h1 con modalita resizible

  const [darkMode, isDarkMode] = useState("light");
  const changeDarkMode = () => {
    if (
      localStorage.theme !== "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
      isDarkMode("dark");
    } else {
      localStorage.setItem("theme", "light");
      isDarkMode("light");
      document.documentElement.classList.remove("dark");
    }
  };

  return (
    <>
      <EventManager />
      <TopMenu darkModes={darkMode} />
      <div
        className="text-black dark:text-white"
        onClick={changeDarkMode}
        style={{ position: "fixed", bottom: "1.4%", left: " 6px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="48"
          viewBox="0 96 960 960"
          width="48">
          <path
            fill="currentColor"
            d="M480 936q-150 0-255-105T120 576q0-150 105-255t255-105q8 0 17 .5t23 1.5q-36 32-56 79t-20 99q0 90 63 153t153 63q52 0 99-18.5t79-51.5q1 12 1.5 19.5t.5 14.5q0 150-105 255T480 936Zm0-60q109 0 190-67.5T771 650q-25 11-53.667 16.5Q688.667 672 660 672q-114.689 0-195.345-80.655Q384 510.689 384 396q0-24 5-51.5t18-62.5q-98 27-162.5 109.5T180 576q0 125 87.5 212.5T480 876Zm-4-297Z"
          />
        </svg>
      </div>
      {darkMode == "light" ? (
        <div className="flex justify-center w-full opacity-50 padding0_5 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-200 via-gray-400 to-gray-600">
          <div className=" text-black ">

            Created By:Edoardo Laurentino PowerdBy: React.js
          </div>
        </div>
      ) : (
        <div className="flex justify-center w-full opacity-50 padding0_5 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-900 to-gray-600 bg-gradient-to-r">
          <div className="text-white ">

            Created By:Edoardo Laurentino PowerdBy: React.js
          </div>
        </div>
      )}
    </>
  );
}

export default App;
